import React from "react";
import LayoutLogged from "../../components/layout/logged";
import Container from '@material-ui/core/Container';
import AppDataGrid from "../../components/data/app-data-grid";
import { ifNotHaveAccessRedirect } from "../../services/auth";

export default function UserIndex() {
  if (ifNotHaveAccessRedirect('user.write')) return <></>;

  return (
    <LayoutLogged title="Usuários">
      <Container >
        <AppDataGrid
          api='/user'
          linksPath='/user'
          columns={[
            {
              field: 'name', type: 'string', headerName: 'Nome', width: 250,
            },
            { field: 'phone', type: 'string', headerName: 'Whatsapp', width: 150, filterable: false, sortable: false },
            {
              field: 'organization', type: 'string', headerName: 'Organização', width: 150,
              valueGetter: (params) => {
                return params?.row?.organization?.name;
              }
            },
          ]}
          showFilterActive
          showFilterIcon
        />
      </Container>
    </LayoutLogged>
  );
}